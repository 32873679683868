const Spacer = ({size}) => (
    <>
        <br/>
        <hr style={{
            borderWidth:`${size}`,
        }}/>
        <br/>
    </>
);
export default Spacer;
